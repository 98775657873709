
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Box, Typography, Button } from '@mui/material';
function Analytics() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}> 
        <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: 'url(https://via.placeholder.com/1920x1080)', // Replace with your background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        textAlign: 'center',
        padding: '20px',
        color: '#fff',
      }}
    >
      {/* Welcome Heading */}
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' }, // Responsive font sizes
          fontWeight: 'bold',
          marginBottom: '20px',
        }}
      >
       Welcome Sri-Lanka Visa!
      </Typography>

      {/* Subheading */}
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' }, // Responsive subheading
          marginBottom: '40px',
          maxWidth: '600px', // Limit width on larger screens
          padding: { xs: '0 10px', sm: '0' }, // Padding for mobile
        }}
      >
        Explore the best tools and resources to help you manage your projects efficiently.
      </Typography>

      {/* Action Button */}
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontSize: { xs: '0.875rem', sm: '1rem' },
          padding: { xs: '10px 20px', sm: '12px 30px' }, // Adjust padding based on screen size
          backgroundColor: '#1976d2', // Primary color
          '&:hover': {
            backgroundColor: '#155a9b', // Darker on hover
          },
        }}
      >
        Get Started
      </Button>
    </Box>
       
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
