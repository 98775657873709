
import Analytics from "layouts/dashboards/analytics";
import Srilanka from "layouts/applications/srilanka";
import ViewPage from "layouts/applications/srilanka/ViewPage"
import SignInBasic from "layouts/authentication/sign-in/basic";
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";
import profilePicture from "assets/images/team-3.jpg";
import AuthWrapper from "AuthWrapper/AuthWrapper";
const routes = [
  {
    type: "collapse",
    name: "Brooklyn Alice",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <AuthWrapper component={Analytics} />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },

  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Srilanka",
        key: "data-tables",
        route: "/applications/srilanka",
        component: <AuthWrapper component={Srilanka} />,
      },
      {
        name: "Srilanka",
        key: "data-table",
        route: "/applications/srilanka/:id",
        component: <AuthWrapper component={ViewPage} />,
        hideInUI: true,
      },
    ],
  },

 


  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
       
     
        ],
      },
    
    ],
    hideInUI: true,
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Docs", key: "title-docs" },

];

export default routes;
